import {HistoryChange} from "./interfaces";

export const FETCH_HISTORY_CHANGES_REQUEST = 'FETCH_HISTORY_CHANGES_REQUEST'
export const FETCH_HISTORY_CHANGES_SUCCESS = 'FETCH_HISTORY_CHANGES_SUCCESS'
export const FETCH_HISTORY_CHANGES_FAILURE = 'FETCH_HISTORY_CHANGES_FAILURE'
export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE'
export const SET_HISTORY_CLEAR = 'SET_HISTORY_CLEAR'

export const fetchHistoryChangesRequest = () => ({
    type: FETCH_HISTORY_CHANGES_REQUEST,
})

export const fetchHistoryChangesSuccess = (data: { history: HistoryChange[], totalCount: number }) => ({
    type: FETCH_HISTORY_CHANGES_SUCCESS,
    payload: data,
})

export const fetchHistoryChangesFailure = (error: any) => ({
    type: FETCH_HISTORY_CHANGES_FAILURE,
    payload: error,
})

export const setHistoryPage = (page: number) => ({
    type: SET_HISTORY_PAGE,
    payload: page,
})

export const setHistoryClear = () => ({
    type: SET_HISTORY_CLEAR,
})