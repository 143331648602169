import React, { useCallback, useEffect, useMemo, useState } from 'react'
import B2BLayout from '../../layouts/b2b'
import { Button, Popconfirm, Table, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons'
import fetchAPI from '../../../lib/utils/fetch-api'
import { ButtonActionWrapper } from '../../components/list-operations/styles'
import styled from 'styled-components'
import { AddModal } from './modal-add'
import { entityList } from './entity-list'
import { ITemplateData } from './interfaces'
import { createSelector } from 'reselect'
import { State } from '../../interfaces'
import { useSelector } from 'react-redux'

const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
  span {
    color: #c5bfbf;
    margin-left: 5px;
  }
`

const createSession = createSelector(
  (state: State) => state.session,
  sessionData => sessionData,
)

const DocumentTemplates = () => {
  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(
    () => [
      {
        href: '/admin',
        title: 'Админка',
      }
    ],
    [],
  )

  const { sessionData } = useSelector(createSession)
  const isGoLeader = sessionData?.roles.includes(16)
  const [officesList, setOfficesList] = useState<{ value: number; label: string; }[]>([])

  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [editingRecord, setEditingRecord] = useState<null | ITemplateData>(null)

  const getOffices = async () => {
    const response = await fetch('/api/get-dealer-offices')
    const data = await response.json()
    const options = data.map(item => ({
        value: item.id,
        label: item.name,
      })
    )
    setOfficesList(options)
  }

  useEffect(() => {
    if (isGoLeader) {
      getOffices()
    }
    getData()
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      fetchAPI('/api/templates/')
        .then(data => {
          setData(data)
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [setData, setLoading])

  /**
   * Удаляет запись
   * @param id
   */
  const remove = (id: number) => {
    fetchAPI(`/api/template/${id}`, { method: 'DELETE' })
      .then(() => { getData() })
      .catch(e => { console.error({e}) })
  }

  const editRecord = useCallback((record: any) => {
    setEditingRecord(record)
    setModalIsOpen(true)
  },[setEditingRecord, setModalIsOpen])

  const handleModalClose = useCallback(() => {
    setEditingRecord(null)
    setModalIsOpen(false)
  },[setEditingRecord, setModalIsOpen])

  const columns = [
    {
      title: 'Название файла',
      dataIndex: 'name',
      width: 190,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      width: 190,
    },
    {
      title: 'Расположение',
      dataIndex: 'entity',
      width: 190,
      render: (name: undefined | string ) => name && entityList?.[name]?.label
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      width: 110,
      style: '',
      render: (_: any, record) => {
        return (
          <span
            style={{ display: 'flex', justifyContent: 'center', gap: 10 }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <Typography.Link>
              <ButtonActionWrapper title='Редактировать' onClick={() => editRecord(record)}>
                <EditOutlined />
              </ButtonActionWrapper>
            </Typography.Link>
            <Typography.Link href={`/api/template/${record.id}`}>
              <ButtonActionWrapper title='Скачать исходный документ'>
                <FileOutlined />
              </ButtonActionWrapper>
            </Typography.Link>
            <Typography.Link>
              <ButtonActionWrapper title='Удалить'>
                <Popconfirm
                  title={<><div>Файл будет удален безвозвратно!</div>Удалить файл?</>}
                  onConfirm={() => remove(record.id)}
                >
                  <DeleteOutlined style={{ color: 'red' }} />
                </Popconfirm>
              </ButtonActionWrapper>
            </Typography.Link>
          </span>
        )
      },
    },
  ]

  if (isGoLeader) {
    columns.unshift({
      title: 'Офис',
      dataIndex: 'office_id',
      width: 190,
      render: (office_id: string | undefined) =>
        officesList?.find(item => item.value === Number(office_id))?.label || office_id
    })
  }

  console.log({ officesList })
  const mergedColumns = columns.map(col => {
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const handleAdd = useCallback(data => {
    getData()
    setModalIsOpen(false)
  },[getData, setModalIsOpen])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MapTitle>Шаблоны документов</MapTitle>
        <Button onClick={() => setModalIsOpen(true)}>
          <PlusOutlined /> Добавить шаблон
        </Button>
        <AddModal
          onOk={handleAdd}
          onCancel={() => handleModalClose()}
          modalIsOpen={modalIsOpen}
          record={editingRecord}
          officesList={officesList}
        />
      </div>
      <Table
        rowKey='id'
        loading={loading}
        size='small'
        scroll={{ x: 'max-content' }}
        dataSource={data}
        columns={mergedColumns}
        rowClassName={'editable-row'}
      />
    </B2BLayout>
  )
}

export default DocumentTemplates
