import React, {
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {Table, Space, message} from 'antd'
import {getColumns} from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {useHistory, useLocation} from 'react-router-dom'
import {Sorter} from '../../../interfaces'
import {objectToUrl} from '../../utils'
import {
  currentFiltersProps,
  Enums,
  TablePaginationPosition,
  DataType,
  IStage
} from './interface'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'
import './index.css'
import ActionButtons from "./action-buttons"
import {VehicleModal} from "../card/modals/modal-vehicle"
import FiltersDrawer from "../../../components/v2/filters-drawer"
import {
  getJsonSettingsByKey,
  TransferItem,
  TableSettingsButton
} from "../../../components/v2/table-settings-button"
import {HStack} from 'src/react-app/ui/Stack'
import {DeliveriesPrintButton} from "../ui/deliveries-print-button/deliveries-print-button"
import {getPrintPath} from "../../../components/v2/export-excel-button"
import {UnloadFromWDModal} from "../card/modals/modal-unload-from-wd"
import { VehicleSummaryTiles } from './vehicle-summary'

import {
  handleCreate,
  handleUnloadFromWD,
  handleUpload,
  handleSendToSchedex,
  handleUnloadFromSchedex,
  handleSetPlanned,
  handleSetVehicleModal,
  isActionButtonEnabled,
  handleCancelVehicleModal,
  handleOkVehicleModal,
  handleCancelUnloadFromWDModal,
  handleOkUnloadFromWDModal
} from './actions'

const createSession = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

function getSingleDeliveryDateValue(filters: currentFiltersProps): string | null {
  const dateFilter = filters?.delivery_date
  if (!dateFilter) return null
  const splitted = dateFilter.split('-')
  if (splitted.length !== 2) return null

  const [start, end] = splitted
  if (start === end) {
    return start
  }
  return null
}

function parseUrlSearchParams(search: string): currentFiltersProps {
  const urlParams = new URLSearchParams(search)
  return {
    delivery_date: urlParams.get('delivery_date') || undefined,
    client_phone: urlParams.get('client_phone') || undefined,
    client_name: urlParams.get('client_name') || undefined,
    id: Number(urlParams.get('id')) || undefined,
    stage: urlParams.get('stage') || undefined,
    old_delivery_date: urlParams.get('old_delivery_date') || undefined,
    order_reference: urlParams.get('order_reference') || undefined,
    vehicle_id: Number(urlParams.get('vehicle_id')) || undefined,
    pyramid_number: Number(urlParams.get('pyramid_number')) || undefined,
    lifting: urlParams.get('lifting') || undefined,
    delivery_complexity: Number(urlParams.get('delivery_complexity')) || undefined,
  }
}

const DeliveriesList = () => {
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState<DataType[]>([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    organization: [],
    vehicle: [],
    pyramid_number: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters, setCurrentFilters] = useState<currentFiltersProps>({})

  const isLeader = true

  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    position: [bottomCenter],
    showSizeChanger: true,
  })

  const { counters } = useSelector(createSession)
  const [localCounter, setLocalCounter] = useState<null | number>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isOpenVehicleModal, setIsOpenVehicleModal] = useState(false)
  const [isOpenUnloadFromWDModal, setIsOpenUnloadFromWDModal] = useState(false)
  const [enums, setEnums] = useState<any>({})

  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, location.pathname))

  useEffect(() => {
    const newFilters = parseUrlSearchParams(location.search)
    setCurrentFilters(newFilters)
  }, [location.search])

  useEffect(() => {
    const newCounterData = counters?.['/deliveries']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/logistics/deliveries',
        title: 'Логистика',
      },
      {
        href: '',
        title: 'Доставки',
        back: true,
      }
    ]
  }, [history])

  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/delivery')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [stages])

  const deleteRecord = useCallback((currentId: number) => {
    fetchAPI(`/api/logistics/deliveries/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getData()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [getStages])

  const getAvailableFilters = useCallback(() => {
    fetchAPI(`/api/logistics/deliveries/enum-list`)
      .then(data => {
        setEnums(data)

        const filters = {
          stage: stages?.map((i: IStage) => ({ label: i.name, value: i.code })) || [],
          vehicle: data?.logistics_vehicle?.map((i: any) => ({ label: i.value, value: i.id })) || [],
          pyramid_number: data?.pyramid_number?.map((i: any) => ({ label: i.value, value: i.id })) || [],
        }
        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [stages])

  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParamsObj = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/list/delivery?${objectToUrl(urlParamsObj)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map((item: any) => ({
              ...item,
              deleteRecord,
              stage: stages && stages.find((stage: any) => stage.code === item.stage),
            })),
          )
          setPagination((prevState) => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, location.search])

  useEffect(() => {
    try {
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
    }
  }, [stages, getData, getAvailableFilters])

  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination((prevState) => ({ ...prevState, ...pagination }))

    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }

    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  const handleRowClick = (record: DataType) => {
    const { id } = record
    history.push(`/logistics/deliveries/${id}`)
  }

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys)
  }

  const changeFiltersHandler = useCallback((filters: any) => {
    let tmpFilter = { ...(filters || {}), pageSize: pagination.pageSize }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [pagination.pageSize, getData, history])

  const uploadProps = useMemo(() => {
    return {
      name: 'file',
      accept: '.csv',
      beforeUpload: handleUpload(getData, setLoading),
      showUploadList: false
    }
  }, [getData, setLoading])

  const columns = useMemo(() => getColumns(isLeader, sorter), [isLeader, sorter])
  const viewColumns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      const result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        let item = columns.find((column) => {
          if ('dataIndex' in column) {
            return jsonSettings[i] === column.dataIndex
          }
          return false
        })
        if (item) {
          result.push(item)
        }
      }
      return result
    }
    return columns
  }, [columns, jsonSettings])


  const excelPath = useMemo(() => {
    return getPrintPath('/api/list/delivery', new URLSearchParams(location.search))
  }, [location.search])

  const settingsColumns = useMemo(() => {
    return columns.map((column) => ({
      key: 'dataIndex' in column ? column.dataIndex : undefined,
      title: column.title,
    }))
  }, [columns]) as TransferItem[]

  const onCancelVehicleModal = useMemo(() => handleCancelVehicleModal(setIsOpenVehicleModal), [])
  const onOkVehicleModal = useMemo(() => handleOkVehicleModal({
    selectedRowKeys,
    setLoading,
    getData,
    setSelectedRowKeys,
    setIsOpenVehicleModal
  }), [selectedRowKeys, setLoading, getData])

  const onCancelUnloadFromWDModal = useMemo(() => handleCancelUnloadFromWDModal(setIsOpenUnloadFromWDModal), [])
  const onOkUnloadFromWDModal = useMemo(() => handleOkUnloadFromWDModal({
    setLoading,
    getData,
    setSelectedRowKeys,
    setIsOpenUnloadFromWDModal
  }), [])

  const onCreate = useMemo(() => handleCreate(history), [history])
  const onUnloadFromWD = useMemo(() => handleUnloadFromWD(setIsOpenUnloadFromWDModal), [])
  const onSendToSchedex = useMemo(() => handleSendToSchedex({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])
  const onUnloadFromSchedex = useMemo(() => handleUnloadFromSchedex({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])
  const onSetPlanned = useMemo(() => handleSetPlanned({
    selectedRowKeys,
    setSelectedRowKeys,
    setLoading,
    getData
  }), [selectedRowKeys, getData])
  const onSetVehicle = useMemo(() => handleSetVehicleModal(setIsOpenVehicleModal), [])

  const checkIsActionButtonEnabled = useMemo(
    () => isActionButtonEnabled(selectedRowKeys, data),
    [selectedRowKeys, data]
  )

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <Space style={{ marginBottom: '20px', width: '100%', justifyContent: 'space-between' }} align={'center'} wrap>
        <ActionButtons
          onCreate={onCreate}
          onUnloadFromWD={onUnloadFromWD}
          uploadProps={uploadProps}
          onSendToSchedex={onSendToSchedex}
          onUnloadFromSchedex={onUnloadFromSchedex}
          onSetPlanned={onSetPlanned}
          onSetVehicle={onSetVehicle}
          isSendToSchedexEnabled={checkIsActionButtonEnabled('new')}
          isUnloadFromSchedexEnabled={checkIsActionButtonEnabled('sent_to_schedex')}
          isSetPlannedEnabled={checkIsActionButtonEnabled('uploaded_from_schedex')}
          isSetVehicleEnabled={checkIsActionButtonEnabled('planned')}
        />

        <FiltersDrawer
          filters={[
            {
              name: 'id',
              label: 'ID',
              type: 'input-integer',
              value: currentFilters?.id,
            },
            {
              name: 'pyramid_number',
              label: 'Пирамида №',
              type: 'select',
              value: currentFilters?.pyramid_number,
              options: {
                enum: availableFilters?.pyramid_number,
              },
            },
            {
              name: 'order_reference',
              label: '№ ТЗ',
              type: 'input-string',
              value: currentFilters?.order_reference,
            },
            {
              name: 'client_name',
              label: 'Имя клиента',
              type: 'input-string',
              value: currentFilters?.client_name,
            },
            {
              name: 'client_phone',
              label: 'Телефон клиента',
              type: 'phone',
              value: currentFilters?.client_phone,
            },
            {
              name: 'stage',
              label: 'Фильтр по стадии',
              type: 'select',
              value: currentFilters?.stage,
              options: {
                enum: availableFilters?.stage,
              },
            },
            {
              name: 'delivery_date',
              label: 'Плановая дата доставки',
              type: 'dates',
              value: currentFilters?.delivery_date,
            },
            {
              name: 'old_delivery_date',
              label: 'Первичная дата доставки',
              type: 'dates',
              value: currentFilters?.old_delivery_date,
            },
            {
              name: 'vehicle_id',
              label: 'Машина',
              type: 'select',
              value: currentFilters?.vehicle_id,
              options: {
                enum: availableFilters?.vehicle,
              },
            },
            {
              name: 'lifting',
              label: 'Подъем',
              type: 'select',
              value: currentFilters?.lifting,
              options: {
                enum: [
                  { label: 'Да', value: 'y' },
                  { label: 'Нет', value: 'n' }
                ],
              },
            },
            {
              name: 'delivery_complexity',
              label: 'Сложность',
              type: 'input-integer',
              value: currentFilters?.delivery_complexity,
            },
          ]}
          onChange={changeFiltersHandler}
          currentFilters={currentFilters}
        />
      </Space>
      <VehicleSummaryTiles data={data} currentFilters={currentFilters} />
      <HStack gap={'24'} align={'start'} justify={'end'} style={{ marginBottom: '20px' }}>
        <DeliveriesPrintButton
          path={excelPath}
          columns={viewColumns}
          stages={stages}
        />
        <TableSettingsButton items={settingsColumns}/>
      </HStack>

      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={viewColumns}
        rowKey={(r) => r.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={(record) => ({ onClick: () => handleRowClick(record) })}
        scroll={{ x: 'max-content' }}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
        rowClassName={(record) => {
          const pyramidNumber = parseInt(record.pyramid_number as string, 10)
          return `pyramid-${pyramidNumber}`
        }}
      />

      {/* Модалка "Назначить машину" */}
      <VehicleModal
        isModalOpen={isOpenVehicleModal}
        onCancel={onCancelVehicleModal}
        onOk={onOkVehicleModal}
        enums={enums}
      />

      {/* Модалка "Выгрузить заказы из WD" */}
      <UnloadFromWDModal
        isModalOpen={isOpenUnloadFromWDModal}
        onCancel={onCancelUnloadFromWDModal}
        onOk={onOkUnloadFromWDModal}
      />
    </B2BLayout>
  )
}

export default DeliveriesList
