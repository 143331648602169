import styled from 'styled-components'

export const FilterWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: 8px;
`

export const VehicleCard = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  width: 300px;
  position: relative;
  padding-right: 20px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.18);
  }
`

export const VehicleCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 10px;
  margin-bottom: 8px;
`

export const VehicleCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: bold;
`

export const VehicleCardIcon = styled.div`
  font-size: 24px;
`