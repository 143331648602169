export const entityList = {
  order_page: {
    label: 'Страница заказа',
  },
  customer_page: {
    label: 'Страница клиента',
    disabled: true,
  },
  measurement_page: {
    label: 'Страница замера',
    disabled: true,
  },
  montage_page: {
    label: 'Страница монтажа',
    disabled: true,
  },
}
