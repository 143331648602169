import { useState, useEffect } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { ICandidateData, IColumn, IResource, IFilterOption } from '../interfaces/interfaces'
import { monthOrder } from '../constants'

export const useFetchData = (
    selectedYears: string[],
    selectedMonths: string[],
    selectedResources: string[],
    selectedDepartments: string[],
    selectedVacancies: string[],
    selectedManagers: string[],
) => {
  const [stagesData, setStagesData] = useState<any[]>([])
  const [paymentsData, setPaymentsData] = useState<any[]>([])
  const [years, setYears] = useState<IFilterOption[]>([])
  const [resources, setResources] = useState<IResource[]>([])
  const [departments, setDepartments] = useState<IFilterOption[]>([])
  const [vacancies, setVacancies] = useState<IFilterOption[]>([])
  const [managers, setManagers] = useState<IFilterOption[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false)
  const [isPaymentsLoaded, setIsPaymentsLoaded] = useState<boolean>(false)
  const [cancelReasons, setCancelReasons] = useState<{ name: string, value: number }[]>([])
  const [arResultVacancy, setArResultVacancy] = useState<{ vacancy: string, count: number }[]>([])

  useEffect(() => {
    loadFilterData()
    loadPaymentsData()
  }, [])

  useEffect(() => {
    if (initialLoadComplete) {
      loadData()
    }
  }, [
    selectedYears,
    selectedMonths,
    selectedResources,
    selectedDepartments,
    selectedVacancies,
    selectedManagers,
    initialLoadComplete,
  ])

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const loadFilterData = async () => {
    setLoading(true)

    const result = await fetchAPI('/api/hr/request/report/filters', {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    })

    const { years, resources, departments, vacancies, managers } = result

    setYears(years)
    setResources(resources)
    setDepartments(departments)
    setVacancies(vacancies)
    setManagers(managers)
    setLoading(false)
    setInitialLoadComplete(true)
  }

  const loadPaymentsData = async () => {
    if (isPaymentsLoaded) return

    const paymentsResult = await fetchAPI('/api/hr/payments/?page_size=all', {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    })

    const paymentsData = paymentsResult.data || []
    setPaymentsData(paymentsData)
    setIsPaymentsLoaded(true)
  }

  const loadData = async () => {
    setLoading(true)
    const filters: any = {
      years: selectedYears.join(','),
      months: selectedMonths.join(','),
      resources: selectedResources.join(','),
      departments: selectedDepartments.join(','),
      vacancies: selectedVacancies.join(','),
      managers: selectedManagers.join(','),
    }

    const queryString = new URLSearchParams(filters).toString()

    const result = await fetchAPI(`/api/hr/request/report/?${queryString}`, {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    })

    const data = result.data || []

    const formattedData: ICandidateData[] = []
    const cancelReasonCounts: Record<string, number> = {}
    const arResultVacancyTemp: { vacancy: string, count: number }[] = []

    data.forEach((item: any) => {
      let date = new Date(item.created_at)
      if (/*(item.stage === 'Завершен') && */item.cancel_reason === null && item.start_probation_period) {
        date = new Date(item.start_probation_period)
      }
      const monthEn = capitalizeFirstLetter(date.toLocaleString('en-US', {month: 'long', timeZone: 'UTC'}))

      const cancelReason = item.cancel_reason
      if (cancelReason) {
        if (!cancelReasonCounts[cancelReason]) {
          cancelReasonCounts[cancelReason] = 0
        }
        cancelReasonCounts[cancelReason]++
      }

      if (/*item.stage === 'Завершен' && */item.cancel_reason === null && item.start_probation_period) {
        if(selectedMonths.length > 0 && !selectedMonths.includes(monthEn)) {
          return
        }
        const existingVacancy = arResultVacancyTemp.find(v => v.vacancy === item.vacancy)
        if (existingVacancy) {
          existingVacancy.count += 1
        } else {
          arResultVacancyTemp.push({ vacancy: item.vacancy, count: 1 })
        }
      }
    })

    const isDateInFilter = (dateStr: string) => {
      const date = new Date(dateStr)
      const monthEn = capitalizeFirstLetter(date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' }))
      const year = date.getFullYear().toString()

      return (selectedYears.length === 0 || selectedYears.includes(year)) &&
        (selectedMonths.length === 0 || selectedMonths.includes(monthEn))
    }

    const stagesFormattedData: Record<string, any> = data.reduce((acc: any, item: any) => {
      const datesToCheck = [
        { field: 'created_at', value: item.created_at },
        { field: 'datetime_interview', value: item.datetime_interview },
        { field: 'start_probation_period', value: item.start_probation_period },
        { field: 'completed_at', value: item.completed_at }
      ]

      datesToCheck.forEach(({ value, field }) => {
        if (value && isDateInFilter(value)) {
          const date = new Date(value)
          const month = capitalizeFirstLetter(date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' }))
          const year = date.getFullYear().toString()
          const key = `${month}_${year}`

          if (!acc[key]) {
            acc[key] = {
              month,
              year,
              total: new Set(),
              interview: new Set(),
              rejected: new Set(),
              accepted: new Set(),
              hired: new Set(),
            }
          }

          // Всего откликов
          if (field === 'created_at' && item.created_at && isDateInFilter(item.created_at)) {
            acc[key].total.add(item.phone)
          }

          // Проведено собеседований
          if (field === 'datetime_interview' && item.datetime_interview && isDateInFilter(item.datetime_interview)) {
            acc[key].interview.add(item.phone)
          }

          // Отказано
          if (field === 'completed_at' && item.cancel_reason !== null && item.completed_at && isDateInFilter(item.completed_at)) {
            acc[key].rejected.add(item.phone)
          }

          // Приняты на работу
          if (field === 'start_probation_period' && item.cancel_reason === null && item.start_probation_period && isDateInFilter(item.start_probation_period)) {
            acc[key].accepted.add(item.phone)
          }
        }
      })

      return acc
    }, {})

    const stagesDataProcessed = Object.values(stagesFormattedData).map((item: any) => ({
      ...item,
      total: item.total.size,
      interview: item.interview.size,
      rejected: item.rejected.size,
      accepted: item.accepted.size,
    }))

    const monthLabelToIndex = monthOrder.reduce((map, { label }, index) => ({ ...map, [label]: index }), {})
    const sortedData = stagesDataProcessed.sort((a, b) => monthLabelToIndex[a.month] - monthLabelToIndex[b.month])
    setStagesData(sortedData)

    const cancelReasonsArray = Object.keys(cancelReasonCounts).map(reason => ({
      name: reason,
      value: cancelReasonCounts[reason],
    }))
    setCancelReasons(cancelReasonsArray)
    setArResultVacancy(arResultVacancyTemp)
    setLoading(false)
  }

  const refetchData = () => {
    loadData()
  }

  return {
    stagesData,
    paymentsData,
    years,
    resources,
    departments,
    vacancies,
    managers,
    loading,
    refetchData,
    cancelReasons,
    arResultVacancy,
  }
}
