import React, { useEffect, useState, useMemo } from 'react'
import { Space, Modal, Table } from 'antd'
import moment from 'moment'
import type { DataType } from '../interface'
import type { currentFiltersProps } from '../interface'
import {VehicleCard, VehicleCardContainer, VehicleCardIcon, VehicleCardTitle} from "../styles";
import dayjs from "dayjs";


function getSingleDeliveryDateValue(deliveryDateFilter?: string): string | null {
  if (!deliveryDateFilter) return null
  const splitted = deliveryDateFilter.split('-')
  if (splitted.length !== 2) return null
  const [start, end] = splitted
  return start === end ? start : null
}

interface IVehicleSummaryTilesProps {
  data: DataType[]
  currentFilters: currentFiltersProps
}

export const VehicleSummaryTiles: React.FC<IVehicleSummaryTilesProps> = ({
                                                                           data,
                                                                           currentFilters,
                                                                         }) => {
  const [vehicleSummaries, setVehicleSummaries] = useState<any[]>([])

  const [isVehicleSummaryModalOpen, setIsVehicleSummaryModalOpen] = useState(false)
  const [currentVehicleOrders, setCurrentVehicleOrders] = useState<any[]>([])

  const [currentVehicleName, setCurrentVehicleName] = useState<string>('')

  const modalColumns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'sequence_in_run',
        key: 'sequence_in_run',
      },
      {
        title: 'Номер ТЗ',
        dataIndex: 'order_reference',
        key: 'order_reference',
      },
      {
        title: 'Время начала',
        dataIndex: 'drop_window_start',
        key: 'drop_window_start',
        render: (value) => value
          ? dayjs(value).format('HH:mm')
          : '-',
      },
      {
        title: 'Расположение',
        dataIndex: 'customer_location_address',
        key: 'customer_location_address',
      },
      {
        title: 'Вес',
        dataIndex: 'capacity',
        key: 'capacity',
        render: (value) => value + 'кг'
      },
    ],
    []
  )

  const handleVehicleCardClick = (summaryItem: any) => {
    setCurrentVehicleOrders(summaryItem.orders || [])
    setCurrentVehicleName(summaryItem.orders[0]?.vehicle || summaryItem.vehicleName)
    setIsVehicleSummaryModalOpen(true)
  }

  const handleCloseVehicleSummaryModal = () => {
    setCurrentVehicleOrders([])
    setIsVehicleSummaryModalOpen(false)
  }

  useEffect(() => {
    const singleDateStr = getSingleDeliveryDateValue(currentFilters?.delivery_date)
    if (!singleDateStr) {
      setVehicleSummaries([])
      return
    }

    const filteredOrders = data.filter((order) => {
      if (!order.delivery_date) return false
      const orderDate = dayjs(order.delivery_date).format('YYYY.MM.DD')

      return orderDate == singleDateStr
    })

    console.log('filteredOrders', filteredOrders)

    const validOrders = filteredOrders.filter(
      (o) => o.pyramid_number && o.vehicle_id && o.sequence_in_run
    )

    const groupedByVehicle: Record<string, DataType[]> = {}
    validOrders.forEach((o) => {
      const vId = String(o.vehicle_id)
      if (!groupedByVehicle[vId]) {
        groupedByVehicle[vId] = []
      }
      groupedByVehicle[vId].push(o)
    })
    console.log('groupedByVehicle', groupedByVehicle)

    const summaries = Object.keys(groupedByVehicle).map((vehicleId) => {
      const ordersArr = groupedByVehicle[vehicleId]
      let totalCapacity = 0
      let totalVolume = 0

      ordersArr.forEach((ord) => {
        totalCapacity += Number(ord.capacity) || 0
        totalVolume += Number(ord.volume) || 0
      })

      return {
        vehicle_id: vehicleId,
        vehicleName: ordersArr[0].vehicle,
        totalCapacity,
        totalVolume,
        orders: ordersArr,
      }
    })

    setVehicleSummaries(summaries)
  }, [data, currentFilters])


  if (!vehicleSummaries || vehicleSummaries.length === 0) {
    return null
  }

  const totalWeight = currentVehicleOrders.reduce(
    (acc, ord) => acc + Number(ord.capacity || 0),
    0
  )

  return (
    <div style={{ marginBottom: 20 }}>
      <VehicleCardContainer>
        {vehicleSummaries.map((item) => (
          <VehicleCard
            key={item.vehicle_id}
            onClick={() => handleVehicleCardClick(item)}
          >
            <VehicleCardTitle>
              <VehicleCardIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24px" width="24px" viewBox="0 0 512 512">
                    <path d="M507.699,229.009l-51.2-76.809c-4.745-7.125-12.74-11.401-21.299-11.401H332.8v-25.6c0-14.14-11.46-25.6-25.6-25.6H25.6    C11.46,89.6,0,101.06,0,115.2v230.4c0,14.14,11.46,25.6,25.6,25.6h65.297c5.948,29.175,31.795,51.2,62.703,51.2    c30.908,0,56.755-22.025,62.703-51.2h117.794c5.948,29.175,31.795,51.2,62.703,51.2s56.755-22.025,62.703-51.2H486.4    c14.14,0,25.6-11.46,25.6-25.6V243.209C512,238.157,510.507,233.216,507.699,229.009z M153.6,396.8    c-21.205,0-38.4-17.195-38.4-38.4s17.195-38.4,38.4-38.4c21.205,0,38.4,17.195,38.4,38.4S174.805,396.8,153.6,396.8z M307.2,345.6    h-90.897c-5.948-29.175-31.795-51.2-62.703-51.2c-30.908,0-56.755,22.025-62.703,51.2H25.6V320h25.6v-25.6H25.6V115.2h281.6V345.6    z M332.8,166.4h102.4l45.756,68.642l-68.25-17.067c-1.015-0.247-2.056-0.375-3.106-0.375h-76.8V166.4z M396.8,396.8    c-21.205,0-38.4-17.195-38.4-38.4s17.195-38.4,38.4-38.4c21.205,0,38.4,17.195,38.4,38.4S418.005,396.8,396.8,396.8z M486.4,281.6    h-25.6v25.6h25.6v38.4h-26.897c-5.948-29.175-31.795-51.2-62.703-51.2s-56.755,22.025-62.703,51.2H332.8V243.2h75.221    l78.379,19.601V281.6z"/>
                </svg>
              </VehicleCardIcon>
              {item.vehicleName}
            </VehicleCardTitle>
            <br/>
            <h4><b>Кол-во заказов:</b> {item.orders.length}</h4>
            <h4><b>Вес:</b> {parseFloat(item.totalCapacity).toFixed(2)}кг</h4>
          </VehicleCard>
        ))}
      </VehicleCardContainer>
      <Space wrap>

      </Space>

      <Modal
        title={currentVehicleName}
        visible={isVehicleSummaryModalOpen}
        onCancel={handleCloseVehicleSummaryModal}
        width={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            Итоговый вес: <b>{parseFloat(totalWeight).toFixed(2)}кг</b>
          </div>
        }
      >
        <Table
          columns={modalColumns}
          dataSource={
            currentVehicleOrders
              ?.slice()
              ?.sort((a, b) => Number(a.sequence_in_run) - Number(b.sequence_in_run))
          }
          rowKey="order_reference"
          pagination={false}
        />
      </Modal>
    </div>
  )
}
