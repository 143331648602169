export const initialTotalByMonth = {
    total: 'Всего',
    office: '',
    total_co: 0,
    total_cancel_before_kp: 0,
    total_cancel_after_kp: 0,
    total_workOrders: 0,
    total_measurements: 0,
    total_contracts: 0,
    total_percent_ld: 0,
    total_percent_md: 0,
    total_percent_cc: 0,
    total_average_score: 0,
    _percent_ldCount: 0,
    _sum: 0,
    _caseDateFactCount: 0,
    _measurementsCount: 0,
    _prepaymentCount: 0,
}