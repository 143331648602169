import React, { memo, useCallback, useState } from 'react'
import { Button, Form, Input, Modal, Select, Upload } from 'antd'
import schema from './schema.json'
import { UploadOutlined } from '@ant-design/icons'
import { entityList } from '../entity-list'
import { AddModalProps } from '../interfaces'
import { UploadFileStatus } from 'antd/es/upload/interface'


export const AddModal: React.FC<AddModalProps> = memo((props: AddModalProps) => {
  const {
    officesList,
    onOk,
    onCancel,
    record,
    modalIsOpen,
  } = props
  if (!modalIsOpen) {
    return null
  }
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleOk = useCallback(async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()
      setLoading(true)
      const formData = new FormData()
      const file = values?.file?.file as File || undefined

      formData.append('file', file)
      formData.append('name', values?.name)
      formData.append('entity', values?.entity)
      if (values?.description) {
        formData.append('description', values.description)
      }
      if (values?.office_id) {
        formData.append('office_id', values.office_id)
      }

      try {
        const url = record?.id ? `/api/template/${record.id}` : '/api/add-template'
        const rawResponse = await fetch(url, {
          method: 'POST',
          body: formData,
        })
        const response = await rawResponse.json()
        onOk?.(response)
      } catch (error) {
        console.warn(error)
        return {error}
      } finally {
        setLoading(false)
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }, [form])

  const fileList = record ? [{
    uid: `file_${record.id}`,
    size: record.size,
    name: record.file_name,
    fileName: record.file_name,
    url: record.url,
    status: 'done' as UploadFileStatus,
  }]: []

  const selectOptions = Object.keys(entityList).map(key => {
    const params = entityList[key]
    return {
      value: key,
      label: params?.label,
      disabled: params?.disabled,
    }
  })

  return (
    <>
      <Modal
        title={schema.title}
        onOk={handleOk}
        onCancel={onCancel}
        visible={modalIsOpen}
        destroyOnClose={true}
        okButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          labelCol={{ flex: '120px' }}
          labelAlign='left'
          labelWrap
          wrapperCol={{ flex: 1 }}
        >
          {officesList?.length ?
            <Form.Item
              label='Офис'
              name='office_id'
              initialValue={record?.office_id}
              rules={[{ required: true, message: 'Выберите Офис!' }]}
            >
              <Select
                placeholder='Выберите Офис'
                options={officesList}
                disabled={loading}
              />
            </Form.Item> : null
          }
          <Form.Item
            label='Файл'
            name='file'
            rules={!Boolean(record?.id) ? [{ required: true, message: 'Выберите файл!' }] : []}
          >
            <Upload
              name='file'
              accept='.doc, .docx'
              beforeUpload={() => false}
              showUploadList={true}
              defaultFileList={fileList}
              disabled={loading}
            >
              <Button icon={<UploadOutlined/>}>Выбрать DOC</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label='Название'
            name='name'
            rules={[{ required: true, message: 'Укажите название!' }]}
            initialValue={record?.name}
          >
            <Input
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            label='Расположение'
            name='entity'
            initialValue={record?.entity}
            rules={[{ required: true, message: 'Выберите расположение!' }]}
          >
            <Select
              placeholder='Выберите расположение'
              options={selectOptions}
              disabled={loading}
            >
            </Select>
          </Form.Item>
          <Form.Item
            label='Описание'
            name='description'
            initialValue={record?.description}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              disabled={loading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})
