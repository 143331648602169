import {
  FETCH_HISTORY_CHANGES_REQUEST,
  FETCH_HISTORY_CHANGES_SUCCESS,
  FETCH_HISTORY_CHANGES_FAILURE,
  SET_HISTORY_PAGE,
  SET_HISTORY_CLEAR
} from './actions'

const initialState = {
  history: [],
  totalCount: 0,
  loading: false,
  page: 1,
  error: null
}

export const historyChangesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_HISTORY_CHANGES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_HISTORY_CHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload.history,
        totalCount: action.payload.totalCount
      }
    case FETCH_HISTORY_CHANGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_HISTORY_PAGE:
      return {
        ...state,
        page: action.payload
      }
    case SET_HISTORY_CLEAR:
      return {
        ...state,
        history: [],
        totalCount: 0,
        loading: false,
        page: 1,
        error: null
      }
    default:
      return state
  }
}
