import React, {memo, useCallback, useEffect, useState} from 'react'
import {HStack} from '../../../../../ui/Stack'
import {Button, Modal, message} from 'antd'
import {useSelector} from 'react-redux'
import {classNames} from '../../../../../lib/classNames/classNames'
import {ModalAddNote} from '../../../../../views/modal-add-notes-new'
import {createSession} from '../../selectors'

interface DeliveriesFormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  toOrder?: () => void,
  toFinish?: () => void,
  onExportToSchedex?: () => void,
  onReturnToNewStage?: () => void,
}

const titlesButton = {
  0: /*'Отправить в Шедекс'*/'',
  1: /*'Выгружено из Шедекс'*/'',
  2: 'Запланировать',
  3: /*'Выгрузить в WD'*/'',
  4: /*'Загрузить в Шедекс номер машины'*/'',
  5: /*'Завершить'*/'',
}

export const DeliveriesFormsHeader: React.FC<DeliveriesFormsHeaderProps> = memo((props: DeliveriesFormsHeaderProps) => {
  const { className, id, stage = 0, toOrder, toFinish, onExportToSchedex, onReturnToNewStage } = props
  const isDisabledButtons = id === 'new'
  const { sessionData } = useSelector(createSession)

  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)

  const [isLockedSchedex, setIsLockedSchedex] = useState(false)
  const [schedexLockedTimer, setSchedexLockedTimer] = useState<number | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const checkLockStatus = async () => {
      try {
        const response = await fetch('/api/schedex/lock/status')
        const data = await response.json()

        if (data.isLocked) {
          console.log('opks')
          setIsLockedSchedex(true)
          setSchedexLockedTimer(data.timeout)
        }
      } catch (error) {
        console.error('Ошибка проверки блокировки:', error)
        message.error('Ошибка проверки блокировки')
      }
    }

    checkLockStatus()

    const eventSource = new EventSource('http://localhost:7070/api/schedex/events/lock') // Тестирование на локалке
    //const eventSource = new EventSource('/api/schedex/events/lock')

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      setIsLockedSchedex(data.isLocked)

      if (data.isLocked && data.timeout) {
        setSchedexLockedTimer(data.timeout)
      } else {
        setSchedexLockedTimer(null)
      }
    }

    eventSource.onerror = () => {
      console.error('Ошибка подключения к SSE')
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }, [])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      interval = setInterval(() => {
        setSchedexLockedTimer((prev) => {
          if (prev && prev > 0) {
            return prev - 1
          } else {
            clearInterval(interval)
            setIsLockedSchedex(false)
            setIsModalVisible(false)
            return 0
          }
        })
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isLockedSchedex, schedexLockedTimer])

  const handleNextStageOnClick = useCallback(async () => {
    if (toOrder) {
      await toOrder?.()
    }
  }, [toOrder])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const handleExportToSchedex = useCallback(() => {
    console.log('oks')
    console.log('isLockedSchedex', isLockedSchedex)
    console.log('schedexLockedTimer', schedexLockedTimer)
    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      setIsModalVisible(true)
    } else {
      Modal.confirm({
        title: 'Подтвердите действие',
        content: 'Вы уверены, что хотите перевыгрузить в Шедекс?',
        okText: 'Да',
        cancelText: 'Нет',
        onOk: async () => {
          await onExportToSchedex?.()
        }
      })
    }
  }, [id, onExportToSchedex, isLockedSchedex, schedexLockedTimer])

  const handleReturnToNewStage = useCallback(() => {
    Modal.confirm({
      title: 'Подтвердите действие',
      content: 'Вы уверены, что хотите вернуть заказ на новую стадию?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: async () => {
        await onReturnToNewStage?.()
      }
    })
  }, [id, onReturnToNewStage])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        {id === 'new' ? ('Новая доставка') : (<>Доставка {`№ ${id}`}</>)}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        {
          stage > 0 && (
            <Button
              onClick={handleExportToSchedex}
            >
              Перевыгрузить в Шедекс
            </Button>
          )
        }
        {
          stage > 0 && (
            <Button
              onClick={handleReturnToNewStage}
            >
              Вернуть на новую стадию
            </Button>
          )
        }
        {
          titlesButton[stage] && (
            <Button onClick={handleNextStageOnClick}
                    style={{marginBottom: 16, marginTop: 16}}
                    type="primary"
                    disabled={isDisabledButtons}
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'delivery'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
        <Modal
          title="Выгрузка в Шедекс недоступна"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setIsModalVisible(false)}>
              Закрыть
            </Button>,
          ]}
        >
          <p>Превышен лимит вызовов: лимит для загрузки заказов составляет 1 раз в 60 секунд.</p>
          {schedexLockedTimer !== null && (
            <p>Попробуйте снова через <b>{schedexLockedTimer}</b> секунд.</p>
          )}
        </Modal>
      </HStack>
    </HStack>
  )
})
