import React, { memo, useCallback, useEffect, useState } from 'react'
import { Alert, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'
import fetchApi from '../../../../../../../lib/utils/fetch-api'
import fetch from 'isomorphic-fetch'

interface PrintPrepareModalProps {
  id: number | string
  template: null | {
    id: number
    name: string
  }
  isModalPrintOpen: boolean
  onOk?: ((formData) => void) | undefined
  onCancel?: (() => void) | undefined
}

export const PrintPrepareModal: React.FC<PrintPrepareModalProps> = memo((props: PrintPrepareModalProps) => {
  const { id, template, isModalPrintOpen, onOk, onCancel } = props

  if (!isModalPrintOpen) {
    return null
  }
  if (!template?.id) {
    return <Alert message='Неверный ID щаблона' type='error' showIcon />
  }

  const [formData, setFormData] = useState<any>({})
  const [schema, setSchema] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [error, setError] = useState<any>(null)

  const downloadUrl = useCallback( async (url: string) => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(formData),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      const content = await response.json()
      Modal.error({
        title: 'Ошибка',
        content: (<>Не удалось получить документ, неизвестная переменная <b>{content.command}</b></>)
      })
    }
    const blob = await response?.blob()
    if (blob) {
      const disposition = response.headers.get('Content-Disposition')
      const filename = (() => {
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\\2|[^;\n]*)/.exec(disposition)
          if (matches != null && matches[1]) {
            return decodeURIComponent(matches[1].replace(/['"]/g, ''))
          }
        }
        return 'file'
      })()
      const downloadAnchorNode = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      downloadAnchorNode.setAttribute('href', url)
      downloadAnchorNode.setAttribute('download', filename)
      document.body.appendChild(downloadAnchorNode)
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
      window.URL.revokeObjectURL(url)
      onCancel?.()
    }
  }, [formData, onCancel])

  const printTemplate = useCallback(() => {
    const url = `/api/order_print/${template.id}/${id}`
    downloadUrl(url)
  }, [template, id, formData])

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchApi(`/api/order_print_prepare/${template.id}/${id}`)
      const schema = convertData2JsonForm(data)

      const actualData = (() => {
        const newSchema = { ...schema.resultObject }
        const total_cost = Number(newSchema?.['total_cost'])
        const prepayment_sum = Number(newSchema?.['prepayment_sum'])
        if (prepayment_sum < total_cost) {
          newSchema['remains_sum'] = (total_cost - prepayment_sum).toFixed(2)
        }
        return newSchema
      })()

      setFormData(actualData)
      setSchema(schema.resultSchema)
      setLoading(false)
    }
    fetchData()
  }, [])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, schema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    printTemplate()
  }, [formData, schema])

  const handleCancel = () => {
    onCancel?.()
  }

  const onChange = useCallback(data => {
    setFormData(prev => {
      /*
      total_cost      Сумма
      prepayment_sum  Предоплата
      payment_sum     Платеж
      remains_sum     Остаток
      */
      const total_cost = Number(data.formData?.total_cost) || 0
      let prepayment_sum = Number(data.formData?.prepayment_sum) || 0
      let payment_sum = 0
      let remains_sum = 0

      if (total_cost !== prepayment_sum) {
        payment_sum = Number(data.formData?.payment_sum) || 0
        remains_sum = total_cost - prepayment_sum - payment_sum
      }

      if (total_cost < Number(prepayment_sum + payment_sum)) {
        payment_sum = total_cost - prepayment_sum
        remains_sum = 0
      }

      return {
        ...prev,
        ...data.formData,
        total_cost: total_cost.toFixed(2),
        prepayment_sum: prepayment_sum.toFixed(2),
        payment_sum: payment_sum.toFixed(2),
        remains_sum: remains_sum.toFixed(2),
      }
    })
  }, [setFormData])

  return (
    <Modal
      title={`Подготовка к печати: "${template.name}"`}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalPrintOpen}
      destroyOnClose={true}
      okButtonProps={{ disabled: loading }}
      bodyStyle={{
        maxHeight: '70vh',
        overflow: 'auto',
      }}
    >
    {loading ? (
      <Spin />
    ) : (
      error
      ? (<Alert message={error} type='error' showIcon />)
      : (
        <DefaultJsonForm
          formData={formData}
          schema={Object.entries(schema).length ? schema : {}}
          onChange={onChange}
          orientation='horizontal'
        />
      )
    )}
    </Modal>
  )
})
