import React, { memo, useCallback, useEffect, Suspense } from 'react'
import { Tabs } from 'antd'
import cls from '../card-case.module.css'
import { useDispatch} from 'react-redux'
import { setDeliveriesStagePlanClear, setDeliveriesTimelineClear } from '../../../../store/deliveries/actions'
import { getDeliveriesTimeLineData } from '../../../../store/deliveries/action-creators'
import { classNames } from '../../../../lib/classNames/classNames'
import { HStack } from '../../../../ui/Stack'
import { DeliveryTimeline } from '../tab-timeline'
import { DeliveryStagePlan } from '../stage-plan'
import {HistoryTable} from "../../../../components/v2/history-table";
import {setHistoryClear} from "../../../../store/history/actions";

interface DeliveriesTabsProps {
  className?: string
  onChange?: (key: string) => void
  onTabClick?: (key: string) => void
  id: string | number
}

export const DeliveriesTabs: React.FC<DeliveriesTabsProps> = memo((props: DeliveriesTabsProps) => {
  const { className, onChange, onTabClick, id } = props
  const dispatch = useDispatch()

  useEffect(() => {
    let mounted = true
    dispatch(setDeliveriesTimelineClear())
    dispatch(setDeliveriesStagePlanClear())
    dispatch(setHistoryClear())
    if (id !== 'new') {
      if (mounted) {
        dispatch(getDeliveriesTimeLineData(id))
      }
    }
    return () => {
      mounted = false
    }
  }, [id, dispatch])


  const handleOnChange = useCallback((key) => {
    if (onChange) {
      onChange(key)
    }
  }, [onChange])

  const handleOnTabClick = useCallback((key) => {
    if (onTabClick) {
      onTabClick(key)
    }
  }, [onTabClick])

  return <Tabs
      onChange={handleOnChange}
      type='card'
      style={{ marginTop: '2rem', backgroundColor: '#fff', paddingBottom: '2rem' }}
      className={classNames('mapContent', {}, [className])}
      onTabClick={handleOnTabClick}
    >
    <Tabs.TabPane tab='Лента событий' key='1'>
      <HStack align={'start'} justify={'between'}>
        <div className={cls.deliveryFilesTimeline}>
          <DeliveryStagePlan id={id}/>
        </div>
        <div className={cls.deliveryTimeline}>
          <DeliveryTimeline id={id}/>
        </div>
      </HStack>
    </Tabs.TabPane>
    <Tabs.TabPane tab='История изменений' key="2" className='chatBox'>
      <Suspense fallback={''}>
        <HistoryTable entity={'delivery'} entityId={Number(id)}/>
      </Suspense>
    </Tabs.TabPane>
  </Tabs>
})

export default DeliveriesTabs;
