import React from 'react'
import { Skeleton, Statistic } from 'antd'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts'
import { StatisticsCardT, StatisticsContainerT, StatisticsRow } from '../styles'
import { IStatisticsProps } from '../interfaces/interfaces'

const Statistics: React.FC<IStatisticsProps & { loading: boolean }> = ({
    totalResponses,
    totalAccepted,
    totalRejected,
    totalInterviews,
    stagesData,
    loading,
  }) => {
    return (
      <StatisticsContainerT>
        <StatisticsRow>
          <StatisticsCardT>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title='Количество откликов'
                value={Math.round(totalResponses).toLocaleString('ru-RU')}
              />
            )}
          </StatisticsCardT>
          <StatisticsCardT>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title='Проведено собеседований'
                value={Math.round(totalInterviews).toLocaleString('ru-RU')}
              />
            )}
          </StatisticsCardT>
          <StatisticsCardT>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title='Отказы'
                value={Math.round(totalRejected).toLocaleString('ru-RU')}
              />
            )}
          </StatisticsCardT>
          <StatisticsCardT>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title='Приняты на работу'
                value={Math.round(totalAccepted).toLocaleString('ru-RU')}
              />
            )}
          </StatisticsCardT>
        </StatisticsRow>
        <StatisticsRow>
          <StatisticsCardT style={{ width: '100%' }}>
            {loading ? (
                <Skeleton active />
            ) : (
              <div style={{ width: '100%' }}>
                <h3>Динамика действий по месяцам</h3>
                <ResponsiveContainer width='100%' height={400}>
                  <BarChart data={stagesData}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='month' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='total' fill={'#8884d8'} name='Всего'>
                      <LabelList dataKey='total' position='top' />
                    </Bar>
                    <Bar dataKey='interview' fill={'#ff7300'} name='Собеседования'>
                      <LabelList dataKey='interview' position='top' />
                    </Bar>
                    <Bar dataKey='rejected' fill={'#d0ed57'} name='Отказы'>
                      <LabelList dataKey='rejected' position='top' />
                    </Bar>
                    <Bar dataKey='accepted' fill={'#163bc9'} name='Приняты на работу'>
                      <LabelList dataKey='accepted' position='top' />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </StatisticsCardT>
        </StatisticsRow>
      </StatisticsContainerT>
    )
}

export default Statistics
