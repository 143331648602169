import React from 'react'
import { Select } from 'antd'
import {IFilterOption} from "../../model/types/types";

export interface IManagerFilterProps {
  selectedManagers: string[]
  onManagerChange: (managers: string[]) => void
  managers: IFilterOption[]
  style?: React.CSSProperties
}


const { Option } = Select

const ManagerFilter: React.FC<IManagerFilterProps> = ({
  selectedManagers,
  onManagerChange,
  managers,
  style,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedManagers}
      onChange={onManagerChange}
      style={style}
      placeholder="Все менеджеры"
      allowClear
      filterOption={(input, option) => {
        const optionText = option?.children?.toString() || ''
        return optionText.toLowerCase().includes(input.toLowerCase())
      }}
    >
      {managers.map((manager) => (
        <Option key={manager.id} value={manager.id}>
          {manager.value}
        </Option>
      ))}
    </Select>
  )
}

export default ManagerFilter
