import React, { useEffect, useMemo, useState } from 'react'
import { Table, Input, Form, Button, Skeleton } from 'antd'
import { CloseSquareFilled, SaveFilled, EditTwoTone } from '@ant-design/icons'
import { Checkbox } from 'antd'
import { useCallback } from 'react'
import SelectFromEnum from '../../../../components/select-from-enum'
import fetchApi from '../../../../../lib/utils/fetch-api'

function CheckboxEdit({ value, onChange, disabled = false }: any) {
  const localOnChange = useCallback(
    ({ target }) => {
      if (typeof onChange === 'function') {
        onChange(target.checked)
      }
    },
    [onChange],
  )
  return <Checkbox checked={value} onChange={localOnChange} disabled={disabled} />
}

interface IDataNew {
  id?: number | null
  name?: string
  organization_from?: number
  address?: string
  director?: string
  agent_type?: number
  tax_type?: number
  inn?: string
  invoice_curr?: string
  invoice_corr?: string
  bank_name?: string
  bik?: string
  okpo?: string
  kpp?: string
  ogrn?: string
  active?: boolean
  certificate_number?: string
}

function TaxTypeEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Ставка НДС', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])

  const schema = useMemo(() => {
    return (
      {
        type: 'string',
        title: 'Ставка НДС',
        enum: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
        ],
        oneOf: [
          {
            const: 0,
            title: 'без НДС',
          },
          {
            const: 1,
            title: 'НДС по ставке 0%',
          },
          {
            const: 2,
            title: 'НДС чека по ставке 10%',
          },
          {
            const: 3,
            title: 'НДС чека по ставке 18%',
          },
          {
            const: 4,
            title: 'НДС чека по расчётной ставке 10/110',
          },
          {
            const: 5,
            title: 'НДС чека по расчётной ставке 18/118',
          },
          {
            const: 6,
            title: 'НДС чека по ставке 20%',
          },
          {
            const: 7,
            title: 'НДС чека по расчётной ставке 20/120',
          },
        ],
        view: {
          name: 'select-from-enum',
        }
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function OrganizationFromEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Тип организации', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])
  const schema = useMemo(() => {
    return (
      {
        type: 'string',
        title: 'Юр.статус',
        enum: [
          1,
          2,
        ],
        oneOf: [
          {
            const: 1,
            title: 'ООО',
          },
          {
            const: 2,
            title: 'ИП',
          },
        ],
        view: {
          name: 'select-from-enum',
        },
        options: {}
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function AgentInfoTypeEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Тип агента', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])
  const schema = useMemo(() => {
    return (
      {
        type: 'string',
        title: 'Тип агента',
        enum: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
        ],
        oneOf: [
          {
            const: 1,
            title: 'банковский платёжный агент'
          },
          {
            const: 2,
            title: 'банковский платёжный субагент'
          },
          {
            const: 3,
            title: 'платёжный агент'
          },
          {
            const: 4,
            title: 'платёжный субагент'
          },
          {
            const: 5,
            title: 'поверенный'
          },
          {
            const: 6,
            title: 'комиссионер'
          },
          {
            const: 7,
            title: 'иной агент'
          }
        ],
        view: {
          name: 'select-from-enum'
        }
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function getNode({ inputType, editing, dataIndex, record, children }: any) {
  if (inputType === 'checkbox') {
    return editing ? <CheckboxEdit disabled={false} /> : <CheckboxEdit value={record[dataIndex]} disabled />
  }
  if (dataIndex === 'organization_from') {
    return editing ? <OrganizationFromEdit /> : <OrganizationFromEdit value={parseInt(record[dataIndex])} disabled />
  }
  if (dataIndex === 'tax_type') {
    return editing ? <TaxTypeEdit /> : <TaxTypeEdit value={parseInt(record[dataIndex])} disabled />
  }
  if (dataIndex === 'agent_type') {
    return editing ? <AgentInfoTypeEdit /> : <AgentInfoTypeEdit value={parseInt(record[dataIndex])} disabled />
  }
  return editing ? <Input /> : children
}

const TabRequisite = ({ id }: {id: number}) => {
  const [form] = Form.useForm()
  const [data, setData] = useState<IDataNew[]>([])
  const [editingKey, setEditingKey] = useState<null|number>(null)
  const [loading, setLoading] = useState<boolean>(true)


  const EditableCell = useCallback(({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const node = getNode({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children
    })
    const organization_from = form.getFieldValue('organization_from')
    const required = !(organization_from === 1 && dataIndex === 'certificate_number')
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required,
                message: `Укажите ${title}`,
              },
            ]}
          >
            {node}
          </Form.Item>
        ) : node}
      </td>
    )
  }, [form])

  const isEditing = record => record.id === editingKey

  const getData = useCallback( async () => {
    return await fetchApi(`/api/office/${id}/requisites`)
      .then(data => data as IDataNew[])
      .catch(e => { throw e })
  }, [id])

  useEffect(() => {
    getData()
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(errors => {
        console.error({ errors })
      })
  }, [id])

  const edit = record => {
    form.setFieldsValue(record)
    setEditingKey(record.id)
  }

  const cancel = useCallback(record => {
    if (record?.id === 0) {
      setData(data => data.filter(r => r.id !== 0))
    }
    setEditingKey(null)
  }, [setEditingKey, setData])

  const save = useCallback(async (itemId:number) => {
    try {
      const row = await form.validateFields()
      await fetchApi(`/api/office/${id}/requisites`, { method: 'POST', body: JSON.stringify({ ...row, id: itemId }) })
        .then(() => {
          setData(data => data.map(item => (item.id === itemId) ? { ...item, ...row } : item ))
          setEditingKey(null)
        })
        .catch(e => {
          console.error('save data error', e)
        })
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }, [setData])

  const addRow = useCallback(() => {
    const newRow: IDataNew = {
      id: 0,
      name: undefined,
      organization_from: undefined,
      address: undefined,
      director: undefined,
      agent_type: undefined,
      tax_type: undefined,
      inn: undefined,
      invoice_curr: undefined,
      invoice_corr: undefined,
      bank_name: undefined,
      bik: undefined,
      okpo: undefined,
      kpp: undefined,
      ogrn: undefined,
      active: true,
      certificate_number: undefined,
    }
    const nextData = [newRow, ...data].map((item, index) => ({ ...item, id: index }))
    setData(nextData)
    form.setFieldsValue(newRow)
    setEditingKey(0)
  }, [data, form])

  const columns: any = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: 300,
      editable: true,
    },
    {
      title: 'Юр. статус',
      dataIndex: 'organization_from',
      width: 300,
      editable: true,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      width: 300,
      editable: true,
    },
    {
      title: 'Генеральный директор',
      dataIndex: 'director',
      width: 300,
      editable: true,
    },
    {
      title: 'Тип агента',
      dataIndex: 'agent_type',
      width: 300,
      editable: true,
    },
    {
      title: 'Ставка НДС',
      dataIndex: 'tax_type',
      width: 300,
      editable: true,
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      width: '10%',
      editable: true,
    },
    {
      title: 'Номер свидетельства',
      dataIndex: 'certificate_number',
      width: '10%',
      editable: true,
    },
    {
      title: 'Расчетный счет',
      dataIndex: 'invoice_curr',
      width: '15%',
      editable: true,
    },
    {
      title: 'Корр. счет',
      dataIndex: 'invoice_corr',
      width: '15%',
      editable: true,
    },
    {
      title: 'Название банка',
      dataIndex: 'bank_name',
      width: '10%',
      editable: true,
    },
    {
      title: 'БИК',
      dataIndex: 'bik',
      width: '10%',
      editable: true,
    },
    {
      title: 'ОКПО',
      dataIndex: 'okpo',
      width: '10%',
      editable: true,
    },
    {
      title: 'КПП',
      dataIndex: 'kpp',
      width: '10%',
      editable: true,
    },
    {
      title: 'ОГРН',
      dataIndex: 'ogrn',
      width: '10%',
      editable: true,
    },
    {
      title: 'Активен',
      dataIndex: 'active',
      width: 90,
      editable: true,
    },
    {
      fixed: 'right',
      title: 'Действие',
      width: 150,
      dataIndex: 'operation',
      render: (_, record: IDataNew) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <button
              type="button"
              onClick={() => save(record?.id || 0)}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <SaveFilled style={{ color: '#52acff' }} />
            </button>
            <button
              type="button"
              onClick={() => cancel(record)}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <CloseSquareFilled style={{ color: '#52acff' }} />
            </button>
          </span>
        ) : (
          <button
            type="button"
            disabled={Boolean(editingKey)}
            onClick={() => edit(record)}
            style={{
              marginLeft: 10,
              fontSize: 20,
              border: 'none',
              background: 'none',
              cursor: 'pointer',
            }}
          >
            <EditTwoTone />
          </button>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'active' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <>
      { loading ?
        <Skeleton active/>
       :
        <Form form={form} component={false}>
          <Button
            disabled={Boolean(editingKey)}
            onClick={addRow}
            style={{ marginBottom: 10 }}
          >
            Добавить
          </Button>
          <Table
            components={{ body: { cell: EditableCell }}}
            bordered
            rowKey='id'
            dataSource={data}
            columns={mergedColumns}
            rowClassName='editable-row'
            pagination={false}
            scroll={{ x: 4500 }}
          />
        </Form>
      }
    </>
  )
}

export default TabRequisite
