import React, { memo, useCallback, useEffect, useState } from 'react'
import {Alert, Form, message, Modal, Skeleton} from 'antd'
import { useSelector } from 'react-redux'
import {
  getDeliveriesFormData,
  getDeliveriesFormError,
  getDeliveriesFormLoading,
  getVisibleFormData,
} from '../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../ui/Stack'
import { HSteps } from '../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../components/v2/default-json-form'
import { DeliveriesFormsHeader } from './deliveries-form-header/deliveries-form-header'
import { useSaveResult } from '../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'
import fetchAPI from "../../../../../lib/utils/fetch-api";

interface DeliveriesFormsProps {
  className?: string
  id: string | number,
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

export const DeliveriesForms: React.FC<DeliveriesFormsProps> = memo((props: DeliveriesFormsProps) => {
  const {id} = props
  const [form] = Form.useForm()
  const deliveriesFormData = useSelector(getDeliveriesFormData)
  const deliveriesFormIsLoading = useSelector(getDeliveriesFormLoading)
  const deliveriesFormError = useSelector(getDeliveriesFormError)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const {saveResult} = useSaveResult()
  const history = useHistory()

  const saveCase = useCallback(async (is_next_stage, is_done = undefined) => {
    await saveResult(id, visibleFormData, deliveriesFormData?.stages?.current, is_next_stage, is_done)
  }, [history, id, visibleFormData])

  useEffect(() => {
    const redirectUrl = deliveriesFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [deliveriesFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({formData}) => {
      if (!visibleFormData) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.resultObject)
      if (isFilesEdit) {
        return
      }
      visibleFormData.resultObject = {...formData}
      debounceSaveCase()
    }, [visibleFormData, debounceSaveCase]
  )

  const handleToOrder = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  const handleToFinish = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  const handleExportToSchedex = useCallback(async () => {
    try {
      const response = await fetchAPI('/api/schedex/orders/export', {
        method: 'POST',
        body: JSON.stringify({ orders: [Number(id)] }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        if (response.result.failed_orders.length > 0) {
          const errorMessages = response.result.failed_orders.map(order => (
            order.errors.join(', ')
          ))

          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
            onOk() {
            },
          })
        } else {
          message.success('Заказ успешно выгружен в Шедекс')
        }
      } else {
        message.error(response.error || 'Ошибка отправки в Шедекс', 5)
      }
    } catch (error) {
      console.error('Ошибка отправки:', error)
      message.error('Ошибка отправки в Шедекс')
    }
  }, [id])

  const handleReturnToNewStage = useCallback(async () => {
    try {
      const response = await fetchAPI('/api/logistics/deliveries/return', {
        method: 'POST',
        body: JSON.stringify({ orders: [Number(id)] }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        window.location.reload()
      } else {
        message.error(response.error || 'Ошибка возврата заказа на новую стадию', 5)

      }
    } catch (error) {
      console.error('Ошибка:', error)
    } finally {

    }
  }, [id])

  if (deliveriesFormError && !deliveriesFormIsLoading && !deliveriesFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }
  return (
    <>
      <DeliveriesFormsHeader
        id={id}
        stage={deliveriesFormData?.stages?.current}
        toOrder={handleToOrder}
        toFinish={handleToFinish}
        onExportToSchedex={handleExportToSchedex}
        onReturnToNewStage={handleReturnToNewStage}
      />
      <HStack gap={"8"} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{width: '100%'}}
        >
          {deliveriesFormError && !deliveriesFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={deliveriesFormError}
              />
            </div>
          ) : null}
          {!deliveriesFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'deliveriesForm'}>
              {deliveriesFormData?.stages && (
                <HSteps
                  items={Object.values(deliveriesFormData?.stages?.items)}
                  current={deliveriesFormData?.stages?.current}
                  history={deliveriesFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
